<template>
  <v-container ref="message">
    <!-- PC版(StickyNote) -->
    <v-row class="text-center mb-16 hidden-sm-and-down" justify="center">
      <v-col cols="4">
        <StickyNote
          title="COMO"
          sub="治療院向けアプリ"
          description="COMO（コモ）は、日々の体調や生活での問題点など、利用者本人が感じている健康イメージや意思を可視化するために開発された新感覚の治療院専用アプリ（コミュニケーションメモ）です。治療の際に患者さんの体調をCOMOに反映できるだけでなく、その情報から診察や診断、適切な対処法などの情報もサポートもしてくれます。またYOMOGIと組み合わせることで、日々の体調を管理することもできます。 なお、保存機能やセキュリティ管理も最新のシステムを導入していることから、鍼灸院や整骨院、さらにはヨガやアロマ教室などの統合医療従事者、スポーツジムや介護施設、健康関連グッズを販売するショップのカルテとしてもご利用することが可能です。"
        />
      </v-col>
      <v-col cols="1" />
      <v-col cols="4">
        <StickyNote
          title="YOMOGI⁺"
          sub="患者向けアプリ"
          description="YOMOGI⁺（ヨモギプラス）は、身体の状態を自分自身で把握・管理できるアプリで、「YOMOGI」の後継機種です。日々の体調や食事、歩行数などの情報を入力することで、病気と健康の中間である未病（みびょう）状態を可視化します。また、算出されたスコアやタイプに応じて環境に配慮した健康法を指導したり、さらには医療機関への受診の有無などもアドバイスしてくれます。自分の体調を見える化することで、自分では気が付かない小さな身体の変化を見つけ、予防することができることから、日ごろの体調管理として有効です。"
        />
      </v-col>
    </v-row>

    <!-- PC版 -->
    <v-row class="hidden-sm-and-down" justify="center">
      <v-col cols="4">
        <v-img contain max-width="320" src="../../assets/ito-sensei-03.jpg" />
      </v-col>
      <v-col cols="6">
        <TitleWithSubTitle title="MASSAGE" sub="代表挨拶" class="mb-6" />
        <Greeting />
        <Profile />
      </v-col>
    </v-row>

    <!-- SP版 -->
    <v-row class="hidden-md-and-up">
      <v-col>
        <div class="d-flex justify-center text-center">
          <TitleWithSubTitle title="MASSAGE" sub="代表挨拶" />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-center">
          <v-img :aspect-ratio="9 / 10" src="../../assets/ito-sensei-03.jpg" />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="ma-0">
          <Profile />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="ma-0">
          <Greeting />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWithSubTitle from "../molecules/TitleWithSubTitle.vue";
import StickyNote from "../molecules/StickyNote.vue";
import Greeting from "../molecules/Greeting.vue";
import Profile from "../molecules/Profile.vue";
export default {
  name: "Message",
  components: {
    TitleWithSubTitle,
    StickyNote,
    Greeting,
    Profile,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped></style>
